<template>
  <div>
    <hr />
    <h3 class="mb-2 m-0 d-block">Invoice #  {{ invoiceNumber }} Transactions History</h3>

    <skeleton-table v-if="initialize" :rows="10" :columns="fields.length" :buttons="1" :dateSearch="true" />

    <no-records-found
      v-if="showNotFound"
      :text="'No transaction history found for invoice # ' + invoiceNumber"
      btnOneLink="invoices"
    >
      <template #btnOne>
        <feather-icon icon="ArrowLeftIcon" class="mr-50" />
        <span class="align-middle">Go Back</span>
      </template>
    </no-records-found>
    
    <b-card v-else no-body class="action-buttons" :class="{'d-none': mainCardInvisiblity}">
      <b-row class="mb-1">
        <b-col offset-lg="2" lg="10">

          <div class="d-flex flex-column flex-md-row align-items-baseline">
            <v-select
              v-model="status"
              :options="statusOptions"
              :reduce="(singleStatus) => singleStatus.value"
              label="text"
              placeholder="Status"
              class="w-100 d-block mt-1"
            />
                    
            <b-form-datepicker
              v-model="dateFrom"
              :max="maxDate"
              placeholder="Date from"
              reset-button
              class="ml-md-2 rounded mt-1"
              locale="en"
            />

            <b-form-datepicker
              v-model="dateTo"
              :max="maxDate"
              placeholder="Date to"
              reset-button
              class="ml-md-2 rounded mt-1"
              locale="en"
            />

            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon filter-btn ml-md-1 mt-1"
              @click="transactionRefresh"
            >
              <feather-icon icon="SearchIcon" />
            </b-button>
          </div>

          <div class="text-right">
            <small v-if="dateError" class="text-danger mr-5">Both of these fields are required for date range</small>
          </div> 

        </b-col>
      </b-row>

      <b-table
        show-empty
        empty-text="No records found from your search"
        empty-filtered-text="No records found from your search"
        hover
        striped
        :busy.sync="isBusy"
        responsive
        :items="transactions"
        :fields="fields"
        ref="transactionTable"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>
        <template #cell(index)="data">
          {{ data.index + 1 }}
        </template>
        <template #cell(amount)="data"> KSH {{ data.item.amount }} </template>

        <template #cell(status)="data">
          <b-button
            v-if="data.item.status == 'pending'"
            variant="outline-primary"
            class="text-capitalize"
            pill
            size="sm"
          >
            {{ data.item.status }}
          </b-button>

          <b-button
            v-else-if="data.item.status == 'completed'"
            variant="outline-success"
            class="text-capitalize"
            pill
            size="sm"
          >
            {{ data.item.status }}
          </b-button>

          <b-button
            v-else-if="data.item.status == 'error'"
            variant="outline-danger"
            class="text-capitalize"
            pill
            size="sm"
          >
            {{ data.item.status }}
          </b-button>

          <b-button
            v-else-if="data.item.status == 'rejected'"
            variant="outline-danger"
            class="text-capitalize"
            pill
            size="sm"
          >
            {{ data.item.status }}
          </b-button>

          <b-button
            v-else
            variant="outline-primary"
            class="text-capitalize"
            pill
            size="sm"
          >
            {{ data.item.status }}
          </b-button>
        </template>

        <template #cell(image)="data">
          <b-button
            v-if="data.item.image"
            variant="link"
            class="p-0"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            @click="viewTransactionImage(data.item.image)"
          >
            <feather-icon icon="EyeIcon" class="mr-50" />
            <span class="align-middle">View Image</span>
          </b-button>
        </template>

        <template #cell(actions)="row">
          <b-dropdown
            variant="link"
            class="p-0"
            toggle-class="text-decoration-none"
            no-caret
            v-if="row.item.status == 'pending verification'"
          >
            <template v-slot:button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="text-body align-middle mr-25"
              />
            </template>

            <b-dropdown-item
              @click="approveTransaction(row.item.ID)"
            >
              <feather-icon icon="CheckCircleIcon" class="mr-50" />
              <span>Approve Transaction</span>
            </b-dropdown-item>

            <b-dropdown-item
              @click="rejectTransaction(row.item.ID)"
            >
              <feather-icon icon="XCircleIcon" class="mr-50" />
              <span>Reject Transaction</span>
            </b-dropdown-item>
          </b-dropdown>

        </template>

      </b-table>
    </b-card>
    <approve-transaction :id="selectedId" @transaction-refresh="transactionRefresh" />
    <reject-transaction :id="selectedId" @transaction-refresh="transactionRefresh" />
    <view-transaction-image :image="selectedImage"/>
  </div>
</template>

<script>
import {
  BDropdown,
  BDropdownItem,
  BTable,
  BButton,
  BSpinner,
  BCard,
  BFormDatepicker,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BCol,
  BRow,
} from "bootstrap-vue";
import ApproveTransaction from './components/ApproveTransaction.vue';
import RejectTransaction from './components/RejectTransaction.vue';
import ViewTransactionImage from './components/ViewTransactionImage.vue';
import vSelect from "vue-select";
import NoRecordsFound from "@/views/pages/no-records-found/NoRecordsFound.vue";
import Ripple from "vue-ripple-directive";
import SkeletonTable from "@/views/pages/shimmers/SkeletonTable.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  directives: {
    Ripple,
  },
  components: {
    BDropdown,
    BDropdownItem,
    BTable,
    BCard,
    BButton,
    BSpinner,
    BFormDatepicker,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BCol,
    BRow,
    ApproveTransaction,
    RejectTransaction,
    ViewTransactionImage,
    vSelect,
    NoRecordsFound,
    SkeletonTable,
  },
  props:["id", "invoiceNumber"],
  data() {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    return {
      fields: [
        {
          key: "index",
          label: "#",
        },
        {
          key: "amount",
          label: "TRANSACTION AMOUNT",
        },
        {
          key: "mode",
          label: "MODE OF PAYMENT",
        },
        {
          key: "phone",
          label: "Phone",
        },
         {
          key: "receipt_number",
          label: "Transaction Code",
        },
        {
          key: "status",
          label: "STATUS",
        },
        {
          key: "image",
          label: "Transaction Image",
        },
        {
          key: "format_created_at",
          label: "TRANSACTION DATE",
        },
        {
          key: "actions",
          label: "ACTIONS",
        },
      ],
      selectedId: false,
      selectedImage: false,
      maxDate: new Date(today),
      dateFrom: null,
      dateTo: null,
      dateError: false,
      status: null,
      statusOptions: [
        {
          text: "Pending",
          value: "Pending",
        },
        {
          text: "Completed",
          value: "completed",
        },
        {
          text: "Pending Verification",
          value: "pending verification",
        },
        {
          text: "Rejected",
          value: "rejected",
        },
        {
          text: "Error",
          value: "error",
        }
      ],
      isBusy: false,
      initialize: true,
      showNotFound: false,
      noRecordsFound: false,
      mainCardInvisiblity:true,
    };
  },
  methods: {
    transactions(_, callback) {
      let params = "";
      if (this.dateFrom && this.dateTo) {
        params =
          "?date_from=" +
          this.dateFrom +
          "&date_to=" +
          this.dateTo;
      }
      if(this.status && (this.dateFrom && this.dateTo)){
        params += "&status=" + this.status;
      }
      if(this.status && (!this.dateFrom || !this.dateTo)){
        params += "?status=" + this.status;
      }
      this.$http
        .get("/invoices/"+ this.id +"/transaction-history" + params)
        .then((response) => {
          // Pluck the array of items off our axios response
          const items = response.data.data;
          if (items.length == 0) {
            this.noRecordsFound = true;

            if (this.initialize) {
              this.showNotFound = true;
            }
          } else {
            this.noRecordsFound = false;
          }
          this.initialize = false;
          this.isBusy = false;
          this.mainCardInvisiblity = false;
          callback(items);
        })
        .catch((error) => {
          for (let err of error.response.data.error) {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: err,
                icon: "AlertCircleIcon",
                variant: "danger",
              },
            });
          }
          if (this.initialize) {
            this.showNotFound = true;
          }
          this.noRecordsFound = true;
          this.initialize = false;
          this.mainCardInvisiblity = false;
          callback([]);
          this.isBusy = false;
        });
      return null;
    },
    viewTransactionImage(image){
      this.selectedImage = image;

      setTimeout(() => {
        this.$bvModal.show("view-transaction-image");
      }, 100);
    },
    approveTransaction(id){
      this.selectedId = id;

      setTimeout(() => {
        this.$bvModal.show("approve-transaction");
      }, 100);
    },
    rejectTransaction(id){
      this.selectedId = id;

      setTimeout(() => {
        this.$bvModal.show("reject-transaction");
      }, 100);
    },
    transactionRefresh() {
      if ((!this.dateFrom && this.dateTo) || (this.dateFrom && !this.dateTo)) {
        this.dateError = true;
      } else {
        this.dateError = false;
        this.$refs.transactionTable.refresh();
      }
    },
  }
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

@media (max-width: 767px) {
  .filter-btn  {
    display: block !important;
    width: 100%;
  }
}
@media (min-width: 768px) {
  .filter-btn  {
    border-radius: 50%;
  }
}
</style>
