<template>
  <div>
    <hr />
    <h3 class="mb-2 m-0 d-block">Scheduled SMS</h3>

    <skeleton-table v-if="initialize" :rows="10" :columns="fields.length" :buttons="1" :dateSearch="true" />

    <no-records-found v-if="showNotFound" text="No sms are scheduled to be sent" />

    <b-card v-else no-body class="action-buttons" :class="{'d-none': mainCardInvisiblity}">
      <b-row class="mb-1">
        <b-col>
          <div class="mt-2 d-flex flex-column flex-sm-row  justify-content-end align-items-center">
            <v-select
              v-model="batch"
              :options="batchOptions"
              label="text"
              placeholder="Batches"
              class="min-width-inputs mr-1"
            />
            <div class="mr-1 min-width-inputs-parents">
              <b-form-datepicker
                v-model="dateFrom"
                placeholder="Date from"
                reset-button
                class="rounded min-width-inputs"
                locale="en"
              />
              <small class="text-danger" v-if="dateError"
                >Both of these fields are required for date range</small
              >
            </div>

            <div class="mr-1 min-width-inputs-parents">
              <b-form-datepicker
                v-model="dateTo"
                placeholder="Date to"
                reset-button
                class="rounded min-width-inputs"
                locale="en"
              />
            </div>

            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon rounded-circle min-width-inputs-btn"
              @click="scheduledRefresh"
            >
              <feather-icon icon="SearchIcon" />
            </b-button>
          </div>
          <!-- basic -->
        </b-col>
      </b-row>

      <b-table
        show-empty
        empty-text="No records found from your search"
        empty-filtered-text="No records found from your search"
        hover
        striped
        :busy.sync="isBusy"
        :current-page="currentPage"
        :per-page="perPage"
        responsive
        :items="scheduled"
        :fields="fields"
        ref="scheduledTable"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>

        <template #cell(index)="data">
          {{ data.index + 1 }}
        </template>

        
        <template #cell(actions)="row">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-danger"
            class="btn-icon rounded-circle ml-1 mt-1 mt-sm-0"
            v-b-modal.delete-scheduled-message
            @click="deleteScheduleMessage(row.item.ID)"
            style="padding: 4px"
            :disabled="row.item.run_at < Math.floor(Date.now() / 1000)"
          >
            <calendar-cancel></calendar-cancel>
          </b-button>
        </template>
      </b-table>

      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        align="center"
        size="lg"
        v-if="!noRecordsFound"
      ></b-pagination>

      <delete-scheduled-message
        :id="scheduleId"
        @scheduled-refresh="scheduledRefreshWithoutDate"
      />
    </b-card>
  </div>
</template>

<script>
import {
  BPagination,
  BTable,
  BButton,
  BSpinner,
  BCard,
  BFormDatepicker,
  BFormInput,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BCol,
  BRow,
  VBModal,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import DeleteScheduledMessage from "./components/DeleteScheduledMessage.vue";
import CalendarCancel from "@/assets/images/icons/CalendarCancel.vue";
import NoRecordsFound from "@/views/pages/no-records-found/NoRecordsFound.vue";
import SkeletonTable from "@/views/pages/shimmers/SkeletonTable.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  directives: {
    Ripple,
    "b-modal": VBModal,
  },
  components: {
    BTable,
    BPagination,
    BCard,
    BFormDatepicker,
    BButton,
    BSpinner,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BCol,
    BRow,
    DeleteScheduledMessage,
    vSelect,
    CalendarCancel,
    NoRecordsFound,
    SkeletonTable,
  },
  props:["clientId"],
  emits:["refresh-tabs-count"],
  data() {
    return {
      fields: [
        {
          key: "index",
          label: "#",
        },
        {
          key: "format_run_at",
          label: "SCHEDULED DATE",
        },
        {
          key: "title",
          label: "SENDER ID",
        },
        {
          key: "message",
          label: "MESSAGE",
        },
        {
          key: "batch",
          label: "BATCH",
        },
        {
          key: "actions",
          label: "REMOVE",
        },
      ],
      batch: null,
      batchOptions: [],
      dateFrom: null,
      dateTo: null,
      dateError: false,
      scheduleId: null,
      isBusy: false,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      initialize: true,
      showNotFound: false,
      noRecordsFound: false,
      mainCardInvisiblity:true,
    };
  },
  methods: {
    scheduled(ctx, callback) {
      let params;
      params = "?page=" + ctx.currentPage + "&clientid=" + this.clientId;

      if (this.dateFrom && this.dateTo) {
        params += "&date_from=" + this.dateFrom + "&date_to=" + this.dateTo;
      }
      if (this.batch) {
        params += "&batch=" + this.batch.toLowerCase();
      }

      this.$http
        .get("/scheduled-messages/" + params)
        .then((response) => {
          this.totalRows = response.data.total_pages * 10;
          // Pluck the array of items off our axios response
          const items = response.data.data;
          if (items.length == 0) {
            this.noRecordsFound = true;

            if (this.initialize) {
              this.showNotFound = true;
            }
          } else {
            this.noRecordsFound = false;
          }
          this.initialize = false;
          this.isBusy = false;
          this.mainCardInvisiblity = false;
          callback(items);
        })
        .catch((error) => {
          for (let err of error.response.data.error) {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: err,
                icon: "AlertCircleIcon",
                variant: "danger",
              },
            });
          }
          if (this.initialize) {
            this.showNotFound = true;
          }
          this.noRecordsFound = true;
          this.initialize = false;
          this.mainCardInvisiblity = false;
          callback([]);
          this.isBusy = false;
        });
      return null;
    },
    scheduledRefresh() {
      if ((!this.dateFrom && this.dateTo) || (this.dateFrom && !this.dateTo)) {
        this.dateError = true;
      } else {
        this.dateError = false;
        this.$refs.scheduledTable.refresh();
      }
    },
    scheduledRefreshWithoutDate() {
      this.dateError = false;
      this.batch = null;
      this.dateFrom = null;
      this.dateTo = null;
      this.scheduleId = null;
      this.$refs.scheduledTable.refresh();
      this.$emit('refresh-tabs-count');
    },
    deleteScheduleMessage(id) {
      this.scheduleId = id;
    },
  },
  created() {
    this.$http.get("/scheduled-messages/batch" + "?clientid=" + this.clientId).then((response) => {
      for (const batches of response.data.data) {
        this.batchOptions.push(batches.batch);
      }
    });
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@media(min-width: 768px){
  .min-width-inputs {
    min-width: 15rem;
  }
  .min-width-inputs-btn{
    border-radius: 50% !important;
  }
}
@media(min-width: 576px) and (max-width: 767px) {
  .min-width-inputs {
    min-width: 30%;
  }
  .min-width-inputs-parents{
    min-width: 30%;
  }
  .min-width-inputs-btn{
    border-radius: 50% !important;
  }
}
@media(max-width: 575px){
  .min-width-inputs {
    min-width: 100%;
    margin-right: 0px !important;
  }
  .min-width-inputs-parents{
    min-width: 100%;
    margin-right: 0px !important;
    margin-top:8px !important;
  }
  .min-width-inputs-btn{
    margin-top:8px !important;
    border-radius: 5px !important;
    min-width: 100% !important;
  }
}
</style>
