<template>
  <b-modal
    id="reject-client-account"
    centered
    title="Reject Client Account"
    hide-footer
    ref="reject-client-account-ref"
  >
    <b-card-text>Are you sure you want to reject this client account ?</b-card-text>

    <hr />
    
    <div class="d-flex justify-content-end align-items-center">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="secondary"
        @click="hideModal"
      >
        Cancel
      </b-button>

      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="danger"
        class="ml-2"
        :disabled="isLoading"
        @click="rejectAccount"
      >
        <b-spinner small v-if="isLoading" />
        <span v-if="isLoading">&nbsp;</span>
        Reject Account
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import { 
  BModal,
  BCardText,
  BSpinner,
  BButton
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  directives: {
    Ripple,
  },
  components: {
    BModal,
    BCardText,
    BSpinner,
    BButton,
  },
  props:{
    id:{
      type: String,
      required: true,
    },
  },
  emits: ["reject-account-submited"],
  data(){
    return {
      isLoading: false,
    };
  },
  methods:{
    rejectAccount(){
      this.isLoading = true;
      this.$http
        .post("/clients/account/suspended",{
          client_id: this.id,
        })
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: response.data.message,
              icon: "CheckIcon",
              variant: "success",
            },
          });
          this.$emit('reject-account-submited');
          this.isLoading = false;
          this.hideModal();
        })
        .catch(error => {
          for (let err of error.response.data.error) {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title:err,
                icon: "AlertCircleIcon",
                variant: "danger",
              },
            });
          }
          this.isLoading = false;
        })
    },
    hideModal() {
      this.$refs['reject-client-account-ref'].hide();
    }
  }
};
</script>
