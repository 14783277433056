<template>
  <b-modal
    id="grant-balance-postpaid"
    centered
    title="Credit Top-Up To Client (Postpaid)"
    ref="grant-balance-postpaid-ref"
    hide-footer
  >
    <b-alert
        show
        variant="warning"
      >
      <div class="alert-body">
        <feather-icon
          class="mr-25"
          icon="AlertCircleIcon"
        />
        <span class="ml-25">The data inserted here will be visible to client in Invoices.</span>
      </div>
    </b-alert>
    <b-form-group
      label-for="amount"
      label="Amount in Kenya Shillings (KSH)"
    >
      <b-form-input
        id="amount"
        v-model.number="amount"
        type="number"
        name="amount"
        placeholder="XXXX"
      />
      <small class="text-danger" v-if="amount == '' || amount == 0">This field is required</small>
    </b-form-group>

    <b-form-group
      label-for="description"
      label="Payment Description"
    >
      <b-form-input
        id="description"
        v-model="description"
        name="description"
        placeholder="Payment Description"
      />
      <small class="text-danger" v-if="description == '' || description == 0">This field is required</small>
    </b-form-group>

    <b-form-group
      label="Payment Due Date & Time"
      label-for="paymentDate"
      key="paymentDate"
    >
      <flat-pickr
        v-model="paymentDate"
        id="paymentDate"
        inputId="paymentDate"
        class="form-control"
        placeholder="Select Payment Due Date & Time"
        :config="{
          enableTime: true,
          altInput: true,
          altFormat: 'D, M d, Y h:m K',
          dateFormat: 'Z',
          clickOpens:true,
        }"
      />
      <small class="text-danger" v-if="paymentDate == '' || paymentDate == 0">This field is required</small>
    </b-form-group>

    <hr />

    <div class="d-flex justify-content-end align-items-center">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="secondary"
        @click="hideModal"
      >
        Cancel
      </b-button>

      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        class="ml-2"
        :disabled="!amount || !description || !paymentDate || formIsLoading"
        @click="grantBalance"
      >
        <b-spinner small v-if="formIsLoading" />
        <span v-if="formIsLoading">&nbsp;</span>
        Credit Top-Up
      </b-button>
    </div>

  </b-modal>
</template>

<script>
import { 
  BModal,
  BFormGroup,
  BFormInput,
  BSpinner,
  BAlert,
  BButton,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import FlatPickr from "vue-flatpickr-component";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  directives: {
    Ripple,
  },
  components: {
    BModal,
    BFormGroup,
    BFormInput,
    BSpinner,
    BAlert,
    BButton,
    vSelect,
    FlatPickr,
  },
  props:{
    id:{
      type: String,
      required: true,
    },
  },
  emits: ["grant-balance-postpaid-submited"],
  data(){
    return{
      amount:null,
      description:null,
      paymentDate:null,
      formIsLoading: false,
    };
  },
  methods:{
    grantBalance(){
      this.formIsLoading = true;
      this.$http
        .post("/clients/grant-balance",{
          client_id: this.id,
          amount: this.amount,
          mode: "Postpaid",
          message: this.description,
          created_at: this.paymentDate,
        })
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: response.data.message,
              icon: "CheckIcon",
              variant: "success",
            },
          });
          this.formIsLoading = false;
          this.$emit('grant-balance-postpaid-submited');
          this.hideModal();
          this.amount=null;
          this.description=null;
          this.paymentDate=null;
        })
        .catch(error => {
          for (let err of error.response.data.error) {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title:err,
                icon: "AlertCircleIcon",
                variant: "danger",
              },
            });
          }
          this.formIsLoading = false;
        })

    },
    hideModal() {
      this.$refs['grant-balance-postpaid-ref'].hide();
    }
  }
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
