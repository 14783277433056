<template>
  <div>
    <hr />
    <h3 class="mb-2 m-0 d-block">SMS History</h3>

    <skeleton-table v-if="initialize" :rows="10" :columns="fields.length" :dateSearch="true" />

    <no-records-found v-if="showNotFound" text="No sms found in the sms history of this client" />

    <b-card v-else no-body class="action-buttons" :class="{'d-none': mainCardInvisiblity}">
      <b-row class="mb-1">
        <b-col md="6">
          <transition name="fade" mode="out-in">
            <b-row no-gutters v-if="advanceFilters">
              <b-col md="4" class="pr-2 mt-2">
                <v-select
                  v-model="sender"
                  :options="senderIds"
                  label="text"
                  :reduce="(singleSender) => singleSender.value"
                  placeholder="Sender ID"
                />
              </b-col>
              <b-col md="4" class="pr-2 mt-2">
                <b-form-input
                  v-model.trim="recipient"
                  placeholder="Recipient"
                  type="number"
                />
              </b-col>
              <b-col md="4" class="pr-2 mt-2">
                <v-select
                  v-model="status"
                  :options="statusOptions"
                  label="text"
                  placeholder="Status"
                />
              </b-col>
            </b-row>
          </transition>
        </b-col>

        <b-col md="6" class="mt-2">
          <!-- basic -->
          <b-form-group>
            <b-input-group>
              <b-form-datepicker
                v-model="dateFrom"
                :max="maxDate"
                placeholder="Date from"
                reset-button
                class="rounded"
                locale="en"
              />
              <b-form-datepicker
                v-model="dateTo"
                :max="maxDate"
                placeholder="Date to"
                reset-button
                class="ml-2 rounded"
                locale="en"
              />

              <b-input-group-append>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="btn-icon rounded-circle ml-1"
                  @click="outboxRefresh"
                >
                  <feather-icon icon="SearchIcon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <div class="d-flex justify-content-between align-content-baseline">
              <small class="text-danger"
                ><span v-if="dateError"
                  >Both of these fields are required for date range</span
                ></small
              >
              <small
                class="btn-link mr-5"
                @click="advanceFiltersToggle"
                role="button"
                >Advance Filter</small
              >
            </div>
          </b-form-group>
        </b-col>
      </b-row>

      <b-table
        show-empty
        empty-text="No records found from your search"
        empty-filtered-text="No records found from your search"
        hover
        striped
        :busy.sync="isBusy"
        :current-page="currentPage"
        :per-page="perPage"
        responsive
        :items="outbox"
        :fields="fields"
        ref="outboxTable"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>

        <template #cell(index)="data">
          {{ data.index + 1 }}
        </template>

        <template #cell(status)="data">
          <b-button
            v-if="data.item.status == 'failed'"
            variant="outline-danger"
            class="text-capitalize"
            pill
            size="sm"
          >
            {{ data.item.status }}
          </b-button>
          <b-button
            v-else-if="data.item.status == 'delivered'"
            variant="outline-success"
            class="text-capitalize"
            pill
            size="sm"
          >
            {{ data.item.status }}
          </b-button>
          <b-button
            v-else
            variant="outline-primary"
            class="text-capitalize"
            pill
            size="sm"
          >
            {{ data.item.status }}
          </b-button>
        </template>

        <template #cell(error_message)="data">
          <span v-if="data.item.status == 'sent'"
            >The message is sent from Pepeasms platform</span
          >
          <span v-if="data.item.status == 'delivered'"
            >The message is delivered to the recipient</span
          >
          <span v-else>{{ data.item.error_message }}</span>
        </template>
      </b-table>

      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        align="center"
        size="lg"
        v-if="!noRecordsFound"
      ></b-pagination>
    </b-card>
  </div>
</template>

<script>
import {
  BPagination,
  BTable,
  BButton,
  BSpinner,
  BCard,
  BFormDatepicker,
  BFormInput,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BCol,
  BRow,
  VBToggle,
  VBModal,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import SkeletonTable from "@/views/pages/shimmers/SkeletonTable.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import NoRecordsFound from "@/views/pages/no-records-found/NoRecordsFound.vue";

export default {
  directives: {
    Ripple,
    "b-toggle": VBToggle,
    "b-modal": VBModal,
  },
  components: {
    BTable,
    BPagination,
    BCard,
    BFormDatepicker,
    BButton,
    BSpinner,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BCol,
    BRow,
    vSelect,
    NoRecordsFound,
    SkeletonTable,
  },
  props:["clientId"],
  data() {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());

    return {
      fields: [
        {
          key: "index",
          label: "#",
        },
        {
          key: "format_created_at",
          label: "DATE",
        },
        {
          key: "title",
          label: "SENDER ID",
        },
        {
          key: "phone",
          label: "RECIPIENT",
        },
        {
          key: "message",
          label: "MESSAGE",
        },
        {
          key: "cost",
          label: "COST",
        },
        {
          key: "status",
          label: "STATUS",
        },
        {
          key: "error_message",
          label: "STATUS DESCRIPTION",
        },
      ],
      sender: null,
      senderIds: [],
      recipient: null,
      status: null,
      statusOptions: ["sent", "failed", "delivered"],
      advanceFilters: false,
      maxDate: new Date(today),
      dateFrom: null,
      dateTo: null,
      dateError: false,
      isBusy: false,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      initialize: true,
      showNotFound: false,
      noRecordsFound: false,
      mainCardInvisiblity:true,
    };
  },
  methods: {
    outbox(ctx, callback) {
      let params;
      params = "?page=" + ctx.currentPage + "&clientid=" + this.clientId;

      if (this.dateFrom && this.dateTo) {
        params += "&date_from=" + this.dateFrom + "&date_to=" + this.dateTo;
      }
      if (this.sender) {
        params += "&sender=" + this.sender;
      }
      if (this.recipient) {
        params += "&recipient=" + this.recipient;
      }
      if (this.status) {
        params += "&status=" + this.status;
      }

      this.$http
        .get("/outbox/" + params)
        .then((response) => {
          this.totalRows = response.data.total_pages * 10;
          // Pluck the array of items off our axios response
          const items = response.data.data;

          if (items.length == 0) {
            this.noRecordsFound = true;

            if (this.initialize) {
              this.showNotFound = true;
            }
          } else {
            this.noRecordsFound = false;
          }
          this.initialize = false;

          this.isBusy = false;
          this.mainCardInvisiblity = false;
          callback(items);
        })
        .catch((error) => {
          for (let err of error.response.data.error) {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: err,
                icon: "AlertCircleIcon",
                variant: "danger",
              },
            });
          }

          if (this.initialize) {
            this.showNotFound = true;
          }
          this.noRecordsFound = true;
          this.initialize = false;
          this.mainCardInvisiblity = false;
          callback([]);
          this.isBusy = false;
        });
      return null;
    },
    outboxRefresh() {
      if ((!this.dateFrom && this.dateTo) || (this.dateFrom && !this.dateTo)) {
        this.dateError = true;
      } else {
        this.dateError = false;
        this.$refs.outboxTable.refresh();
      }
    },
    advanceFiltersToggle() {
      this.sender = null;
      this.recipient = null;
      this.status = null;
      this.advanceFilters = !this.advanceFilters;
    },
  },

  created() {
    this.$http.get("/sender-id/active" + "&clientid=" + this.clientId).then((response) => {
      for (const senderId of response.data.data) {
        let pushSenderId = {
          value: senderId.ID,
          text: senderId.title,
        };
        this.senderIds.push(pushSenderId);
      }
    });
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.fade-enter-active,
.fade-leave-active {
  transition: all 0.25s ease-in-out;
}
</style>

