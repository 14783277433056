<template>
  <b-modal
    id="change-account-type"
    centered
    :title="'Change Account Type To ' + accountType "
    hide-footer
    ref="change-account-type-ref"
  >
    <b-card-text>Are you sure you want to change <strong>{{name}}</strong> client account type to <strong>{{accountType}}</strong> ?</b-card-text>

    <hr />
    
    <div class="d-flex justify-content-end align-items-center">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="secondary"
        @click="hideModal"
      >
        Cancel
      </b-button>

      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="success"
        class="ml-2"
        :disabled="isLoading"
        @click="changeAccountType"
      >
        <b-spinner small v-if="isLoading" />
        <span v-if="isLoading">&nbsp;</span>
        Change To {{ accountType }}
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import { 
  BModal,
  BCardText,
  BSpinner,
  BButton
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  directives: {
    Ripple,
  },
  components: {
    BModal,
    BCardText,
    BSpinner,
    BButton,
  },
  props:{
    id:{
      type: String,
      required: true,
    },
    name:{
      type: String,
      required: true,
    },
    postpaid:{
      type: Boolean,
      required: true,
    },
  },
  data(){
    return {
      isLoading: false,
    };
  },
  emits: ["change-account-type-submited"],
  computed:{
    accountType(){
      return (this.postpaid) ? "Postpaid" : "Prepaid";
    }
  },
  methods:{
    changeAccountType(){
      this.isLoading = true;
      this.$http
        .post("/clients/account/change-account-type",{
          client_id: this.id,
          postpaid: this.postpaid,
        })
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: response.data.message,
              icon: "CheckIcon",
              variant: "success",
            },
          });
          this.$emit('change-account-type-submited');
          this.isLoading = false;
          this.hideModal();
        })
        .catch(error => {
          for (let err of error.response.data.error) {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title:err,
                icon: "AlertCircleIcon",
                variant: "danger",
              },
            });
          }
          this.isLoading = false;
        })
    },
    hideModal() {
      this.$refs['change-account-type-ref'].hide();
    }
  }
};
</script>
