<template>
  <div>
    <hr />
    <h3 class="mb-2 mb-md-0 m-0 d-block">Sender ID</h3>

    <skeleton-table v-if="initialize" :rows="10" :columns="fields.length" :dateSearch="true" />

    <no-records-found
      v-if="showNotFound"
      text="No sender id found"
    />

    <b-card v-else no-body class="action-buttons" :class="{'d-none': mainCardInvisiblity}">
      <b-row class="mb-1">
        <b-col offset-md="4" offset-xl="6" md="8" xl="6">
          <div class="d-flex justify-content-end align-items-baseline">
            <v-select
              v-model="status"
              :options="statusOptions"
              label="text"
              placeholder="Status"
              class="w-100 d-block"
            />

            <b-form-input
              v-model.trim="search"
              placeholder="Search for Sender ID"
              class="ml-2"
            />

            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon rounded-circle ml-1"
              @click="senderRefresh"
            >
              <feather-icon icon="SearchIcon" />
            </b-button>
          </div>
        </b-col>
      </b-row>

      <b-table
        show-empty
        empty-text="No records found from your search"
        empty-filtered-text="No records found from your search"
        hover
        striped
        :busy.sync="isBusy"
        :current-page="currentPage"
        :per-page="perPage"
        responsive
        :items="senders"
        :fields="fields"
        ref="sendersTable"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>

        <template #cell(index)="data">
          {{ data.index + 1 }}
        </template>

        <template #cell(category)="data">
          <span class="text-capitalize">{{ data.item.category }}</span>
          Application
        </template>

        <template #cell(letter)="data">
          <a
            :href="appClientUrl + '/uploads/sender-id-letters/' + data.item.letter"
            download
            class="btn-link"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          >
            <feather-icon icon="DownloadIcon" class="mr-50" />
            <span class="align-middle">download</span>
          </a>
        </template>

        <template #cell(status)="data">
          <b-button
            v-if="data.item.status == 'pending'"
            variant="outline-primary"
            class="text-capitalize"
            pill
            size="sm"
          >
            {{ data.item.status }}
          </b-button>

          <b-button
            v-else-if="data.item.status == 'active'"
            variant="outline-success"
            class="text-capitalize"
            pill
            size="sm"
          >
            {{ data.item.status }}
          </b-button>

          <b-button
            v-else-if="data.item.status == 'rejected'"
            variant="outline-danger"
            class="text-capitalize"
            pill
            size="sm"
          >
            {{ data.item.status }}
          </b-button>
        </template>

        <template #cell(actions)="row">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            v-if="row.item.status == 'pending'"
            @click="selectSenderId(row.item.ID)"
            v-b-modal.approve-sender-id
            size="sm"
          >
            Approve
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="danger"
            class="ml-1"
            v-if="row.item.status == 'pending'"
            @click="selectSenderId(row.item.ID)"
            v-b-modal.suspend-sender-id
            size="sm"
          >
            Reject
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            v-if="row.item.status == 'rejected'"
            @click="selectSenderId(row.item.ID)"
            v-b-modal.approve-sender-id
            size="sm"
          >
            Re-Activate
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="danger"
            v-if="row.item.status == 'active'"
            @click="selectSenderId(row.item.ID)"
            v-b-modal.suspend-sender-id
            size="sm"
          >
            Suspend
          </b-button>
        </template>
      </b-table>

      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        align="center"
        size="lg"
        v-if="!noRecordsFound"
      ></b-pagination>
    </b-card>
    <suspend-sender-id :id="senderId" @sender-refresh="senderRefresh" />
    <approve-sender-id :id="senderId" @sender-refresh="senderRefresh" />
  </div>
</template>

<script>
import {
  BPagination,
  BTable,
  BButton,
  BSpinner,
  BCard,
  BFormInput,
  BFormCheckbox,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BCol,
  BRow,
  VBToggle,
  VBModal,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";

import SuspendSenderId from "./components/SuspendSenderId.vue";
import ApproveSenderId from "./components/ApproveSenderId.vue";

import SkeletonTable from "@/views/pages/shimmers/SkeletonTable.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { $themeConfig } from "@themeConfig";
import NoRecordsFound from "@/views/pages/no-records-found/NoRecordsFound.vue";

export default {
  directives: {
    Ripple,
    "b-toggle": VBToggle,
    "b-modal": VBModal,
  },
  components: {
    BTable,
    BPagination,
    BCard,
    BButton,
    BSpinner,
    BFormInput,
    BFormCheckbox,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BCol,
    BRow,
    vSelect,
    SuspendSenderId,
    ApproveSenderId,
    NoRecordsFound,
    SkeletonTable,
  },
  props:["clientId"],
  data() {
    const { appClientUrl } = $themeConfig.app;

    return {
      fields: [
        {
          key: "index",
          label: "#",
        },
        {
          key: "title",
          label: "SENDER ID",
        },
        {
          key: "category",
          label: "TYPE",
        },
        {
          key: "letter",
          label: "APPLICATION LETTER",
        },
        {
          key: "status",
          label: "STATUS",
        },
        {
          key: "actions",
          label: "ACTIONS",
        },
      ],
      search: "",
      status: null,
      statusOptions: ["pending", "rejected", "active"],
      senderId: false,
      isBusy: false,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      appClientUrl: appClientUrl,
      initialize: true,
      showNotFound: false,
      noRecordsFound: false,
      mainCardInvisiblity:true,
    };
  },
  methods: {
    senders(ctx, callback) {
      let params = "?page=" + ctx.currentPage  + "&clientid=" + this.clientId;
      if (this.search) {
        params += "&search=" + this.search;
      }
      if(this.status){
        params += "&status=" + this.status;
      }
      this.$http
        .get("/sender-id/" + params)
        .then((response) => {
          this.totalRows = response.data.total_pages * 10;
          // Pluck the array of items off our axios response
          const items = response.data.data;
          if (items.length == 0) {
            this.noRecordsFound = true;

            if (this.initialize) {
              this.showNotFound = true;
            }
          } else {
            this.noRecordsFound = false;
          }
          this.initialize = false;
          this.isBusy = false;
          this.mainCardInvisiblity = false;
          callback(items);
        })
        .catch((error) => {
          for (let err of error.response.data.error) {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: err,
                icon: "AlertCircleIcon",
                variant: "danger",
              },
            });
          }
          if (this.initialize) {
            this.showNotFound = true;
          }
          this.noRecordsFound = true;
          this.initialize = false;
          this.mainCardInvisiblity = false;
          callback([]);
          this.isBusy = false;
        });
      return null;
    },
    selectAll() {
      if (this.allSelected) {
        this.selectedSenders = this.allSendersId;
      } else {
        this.selectedSenders = [];
      }
    },
    senderRefresh() {
      this.senderId = false;
      this.$refs.sendersTable.refresh();
    },
    selectSenderId(id) {
      this.senderId = id;
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
