<template>
  <b-card no-body class="p-2">
    <!-- Media -->
    <b-row>
      <b-col class="col-12 col-xl-2 mb-1 avatar-col">
        <b-avatar
          :src="avatarImage"
          variant="light-primary"
          size="100px"
          rounded
        />
      </b-col>

      <b-col class="col-12 mb-2 d-md-none">
        <div
          class="d-flex justify-content-between align-items-center flex-wrap"
        >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-primary"
            v-show="status == 'active' && postpaid"
            class="mt-1"
            v-b-modal.grant-balance-postpaid
          >
            Credit Top-Up
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-primary"
            v-show="status == 'active' && !postpaid"
            class="mt-1"
            v-b-modal.grant-balance
          >
            Credit Top-Up
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-success"
            v-if="postpaid"
            class="mt-1"
            v-b-modal.change-account-type
          >
            Change To Prepaid Account
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-success"
            v-else
            class="mt-1"
            v-b-modal.change-account-type
          >
            Change To Postpaid Account
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-primary"
            v-show="status == 'pending'"
            class="mt-1"
            v-b-modal.activate-client-account
          >
            Approve Account
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-primary"
            v-show="status == 'suspended'"
            class="mt-1"
            v-b-modal.activate-client-account
          >
            Re-Activate Account
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-danger"
            v-show="status == 'pending'"
            v-b-modal.reject-client-account
          >
            Reject Account
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-danger"
            v-show="status == 'active'"
            class="mt-1"
            v-b-modal.suspend-client-account
          >
            Suspend Account
          </b-button>

          <b-dropdown
            variant="link"
            no-caret
            toggle-class="p-0"
            class="mt-1 p-0"
            right
          >

            <template #button-content>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="btn-icon"
              >
                <feather-icon icon="SettingsIcon" />
              </b-button>
            </template>

            <b-dropdown-item @click="changeTabIndex(0)" :active="tabIndex == 0">
              <span class="align-middle mr-50">Sender ID</span>
              <b-badge variant="primary">{{senderIdCounts}}</b-badge>
            </b-dropdown-item>

            <b-dropdown-item @click="changeTabIndex(1)" :active="tabIndex == 1">
              <span class="align-middle mr-50">SMS History</span>
              <b-badge variant="primary">{{outboxCounts}}</b-badge>
            </b-dropdown-item>

            <b-dropdown-item @click="changeTabIndex(2)" :active="tabIndex == 2">
              <span class="align-middle mr-50">Scheduled SMS</span>
              <b-badge variant="primary">{{scheduledCounts}}</b-badge>
            </b-dropdown-item>

            <b-dropdown-item @click="changeTabIndex(3)" :active="tabIndex == 3">
              <span class="align-middle mr-50">Voice History</span>
              <b-badge variant="primary">{{outboxVoicesCounts}}</b-badge>
            </b-dropdown-item>

            <b-dropdown-item @click="changeTabIndex(4)" :active="tabIndex == 4">
              <span class="align-middle mr-50">Scheduled Voices</span>
              <b-badge variant="primary">{{scheduledVoicesCounts}}</b-badge>
            </b-dropdown-item>

            <b-dropdown-item @click="changeTabIndex(5)" :active="tabIndex == 5">
              <span class="align-middle mr-50">Transaction History</span>
              <b-badge variant="primary">{{transactionCount}}</b-badge>
            </b-dropdown-item>

            <b-dropdown-item @click="changeTabIndex(6)" :active="tabIndex == 6">
              <span class="align-middle mr-50">Contacts</span>
              <b-badge variant="primary">{{contactsCounts}}</b-badge>
            </b-dropdown-item>

            <b-dropdown-item @click="changeTabIndex(7)" :active="tabIndex == 7">
              <span class="align-middle mr-50">Invalid Contacts</span>
              <b-badge variant="primary">{{invalidContactsCounts}}</b-badge>
            </b-dropdown-item>

          </b-dropdown>
        </div>
      </b-col>

      <b-col class="col-12 col-xxl-10">
        <table class="table table-borderless">
          <tbody class="table-lg">
            <tr>
              <td>Name:</td>
              <th>{{ firstName }} {{ lastName }}</th>
              <td>Email:</td>
              <th>{{ email }}</th>
              <td>Website:</td>
              <th>{{ website }}</th>
            </tr>
            <tr>
              <td>Status:</td>
              <th class="text-capitalize">{{ status }}</th>
              <td>Phone:</td>
              <th>{{ phone }}</th>
              <td>Balance:</td>
              <th>KSH {{ balance }}</th>
            </tr>
            <tr>
              <td>User Type:</td>
              <th>
                <span v-if="isCompany">Organization</span>
                <span>Individual</span>
              </th>
              <td>Account Type:</td>
              <th>
                <span v-if="postpaid">Postpaid</span>
                <span v-else>Prepaid</span>
              </th>
              <td>Registered At:</td>
              <th>
                {{ format_created_at }}
              </th>
            </tr>

          </tbody>
          <tbody class="table-md d-md-none">
            <tr>
              <td>Name:</td>
              <th>{{ firstName }} {{ lastName }}</th>
              <td>Email:</td>
              <th>{{ email }}</th>
            </tr>
            <tr>
              <td>Status:</td>
              <th class="text-capitalize">{{ status }}</th>
              <td>Phone:</td>
              <th>{{ phone }}</th>
            </tr>

            <tr>
              <td>Website:</td>
              <th>{{ website }}</th>
              <td>User Type:</td>
              <th>
                <span v-if="isCompany">Organization</span>
                <span>Individual</span>
              </th>
            </tr>
            <tr>
              <td>Balance:</td>
              <th>KSH {{ balance }}</th>
              <td>Account Type:</td>
              <th>
                <span v-if="postpaid">Postpaid</span>
                <span v-else>Prepaid</span>
              </th>
            </tr>
            <tr>
              <td>Registered At:</td>
              <th>
                {{ format_created_at }}
              </th>
            </tr>
          </tbody>
          <tbody class="d-sm-none">
            <tr>
              <td>Name:</td>
              <th>{{ firstName }} {{ lastName }}</th>
            </tr>
            <tr>
              <td>Email:</td>
              <th>{{ email }}</th>
            </tr>
            <tr>
              <td>Status:</td>
              <th class="text-capitalize">{{ status }}</th>
            </tr>
            <tr>
              <td>Phone:</td>
              <th>{{ phone }}</th>
            </tr>
            <tr>
              <td>Website:</td>
              <th>{{ website }}</th>
            </tr>
            <tr>
              <td>User Type:</td>
              <th>
                <span v-if="isCompany">Organization</span>
                <span>Individual</span>
              </th>
            </tr>
            <tr>
              <td>Account Type:</td>
              <th>
                <span v-if="postpaid">Postpaid</span>
                <span v-else>Prepaid</span>
              </th>
            </tr>
            <tr>
              <td>Balance:</td>
              <th>KSH {{ balance }}</th>
            </tr>
            <tr>
              <td>Registered At:</td>
              <th>
                {{ format_created_at }}
              </th>
            </tr>
          </tbody>
        </table>
      </b-col>

      <b-col class="col-12 mt-2 d-none d-md-flex align-items-center justify-content-between">
          <div>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-primary"
              v-show="status == 'active' && postpaid"
              class="mr-2"
              v-b-modal.grant-balance-postpaid
            >
              Credit Top-Up
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-primary"
              class="mr-2"
              v-show="status == 'active' && !postpaid"
              v-b-modal.grant-balance
            >
              Credit Top-Up
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-success"
              v-if="postpaid"
              class="mr-2"
              v-b-modal.change-account-type
            >
              Change To Prepaid Account
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-success"
              v-else
              class="mr-2"
              v-b-modal.change-account-type
            >
              Change To Postpaid Account
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-primary"
              class="mr-2"
              v-show="status == 'pending'"
              v-b-modal.activate-client-account
            >
              Approve Account
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-primary"
              class="mr-2"
              v-show="status == 'suspended'"
              v-b-modal.activate-client-account
            >
              Re-Activate Account
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-danger"
              v-show="status == 'pending'"
              v-b-modal.reject-client-account
            >
              Reject Account
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-danger"
              v-show="status == 'active'"
              v-b-modal.suspend-client-account
            >
              Suspend Account
            </b-button>
          </div>

          <b-dropdown
            variant="link"
            no-caret
            toggle-class="p-0"
            right
          >

            <template #button-content>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="btn-icon"
              >
                <feather-icon icon="SettingsIcon" />
              </b-button>
            </template>

            <b-dropdown-item @click="changeTabIndex(0)" :active="tabIndex == 0">
              <span class="align-middle mr-50">Sender ID</span>
              <b-badge variant="primary">{{senderIdCounts}}</b-badge>
            </b-dropdown-item>

            <b-dropdown-item @click="changeTabIndex(1)" :active="tabIndex == 1">
              <span class="align-middle mr-50">SMS History</span>
              <b-badge variant="primary">{{outboxCounts}}</b-badge>
            </b-dropdown-item>

            <b-dropdown-item @click="changeTabIndex(2)" :active="tabIndex == 2">
              <span class="align-middle mr-50">Scheduled SMS</span>
              <b-badge variant="primary">{{scheduledCounts}}</b-badge>
            </b-dropdown-item>

            <b-dropdown-item @click="changeTabIndex(3)" :active="tabIndex == 3">
              <span class="align-middle mr-50">Voice History</span>
              <b-badge variant="primary">{{outboxVoicesCounts}}</b-badge>
            </b-dropdown-item>

            <b-dropdown-item @click="changeTabIndex(4)" :active="tabIndex == 4">
              <span class="align-middle mr-50">Scheduled Voices</span>
              <b-badge variant="primary">{{scheduledVoicesCounts}}</b-badge>
            </b-dropdown-item>

            <b-dropdown-item @click="changeTabIndex(5)" :active="tabIndex == 5">
              <span class="align-middle mr-50">Invoices</span>
              <b-badge variant="primary">{{invoiceCount}}</b-badge>
            </b-dropdown-item>

            <b-dropdown-item @click="changeTabIndex(6)" :active="tabIndex == 6">
              <span class="align-middle mr-50">Transaction History</span>
              <b-badge variant="primary">{{transactionCount}}</b-badge>
            </b-dropdown-item>

            <b-dropdown-item @click="changeTabIndex(7)" :active="tabIndex == 7">
              <span class="align-middle mr-50">Contacts</span>
              <b-badge variant="primary">{{contactsCounts}}</b-badge>
            </b-dropdown-item>

            <b-dropdown-item @click="changeTabIndex(8)" :active="tabIndex == 8">
              <span class="align-middle mr-50">Invalid Contacts</span>
              <b-badge variant="primary">{{invalidContactsCounts}}</b-badge>
            </b-dropdown-item>

          </b-dropdown>
      </b-col>
    </b-row>

    <b-tabs pills v-model="tabIndex">
      <b-tab lazy active title-item-class="d-none">
        <sender-id :clientId="clientId" />
      </b-tab>

      <b-tab lazy title-item-class="d-none">
        <outbox :clientId="clientId" />
      </b-tab>

      <b-tab lazy title-item-class="d-none">
        <scheduled-messages
          :clientId="clientId"
          @refresh-tabs-count="tabsCounts"
        />
      </b-tab>

      <b-tab lazy title-item-class="d-none">
        <outbox-voices :clientId="clientId" />
      </b-tab>

      <b-tab lazy title-item-class="d-none">
        <scheduled-voices
          :clientId="clientId"
          @refresh-tabs-count="tabsCounts"
        />
      </b-tab>

      <b-tab lazy title-item-class="d-none">
        <invoices :clientId="clientId" @view-transaction-history="viewTransactionHistory" />
      </b-tab>

      <b-tab lazy title-item-class="d-none">
        <transaction-history :clientId="clientId" />
      </b-tab>

      <b-tab lazy title-item-class="d-none">
        <contacts :clientId="clientId" @refresh-tabs-count="tabsCounts" />
      </b-tab>

      <b-tab lazy title-item-class="d-none">
        <invalid-contacts
          :clientId="clientId"
          @refresh-tabs-count="tabsCounts"
        />
      </b-tab>

      <b-tab lazy title-item-class="d-none">
        <invoice-transaction-history :id="transactionId" :invoiceNumber="transactionInvoiceNumber" />
      </b-tab>
    </b-tabs>

    <grant-balance
      :id="clientId"
      @grant-balance-submited="refreshClientGrantBalance"
    />

    <grant-balance-postpaid
      :id="clientId"
      @grant-balance-postpaid-submited="refreshClientGrantBalance"
    />

    <change-account-type
      :id="clientId"
      :name="firstName + ' ' + lastName"
      :postpaid="!postpaid"
      @change-account-type-submited="refreshClientAccountType"
    />

    <suspend-account :id="clientId" @suspend-account-submited="refreshClient" />
    <reject-account :id="clientId" @reject-account-submited="refreshClient" />
    <activate-account
      :id="clientId"
      @activate-account-submited="refreshClient"
    />
  </b-card>
</template>

<script>
import {
  BDropdown,
  BDropdownItem,
  BButton,
  BSpinner,
  BAvatar,
  BCard,
  BTab,
  BTabs,
  BBadge,
  BCol,
  BRow,
  VBModal,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
// import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { $themeConfig } from "@themeConfig";
import GrantBalance from "./components/GrantBalance.vue";
import GrantBalancePostpaid from "./components/GrantBalancePostpaid.vue";
import ChangeAccountType from "./components/ChangeAccountType.vue";
import SuspendAccount from "./components/SuspendAccount.vue";
import RejectAccount from "./components/RejectAccount.vue";
import ActivateAccount from "./components/ActivateAccount.vue";
import SenderId from "./tabs/SenderId.vue";
import Outbox from "./tabs/Outbox.vue";
import ScheduledMessages from "./tabs/ScheduledMessages.vue";
import OutboxVoices from "./tabs/OutboxVoices.vue";
import ScheduledVoices from "./tabs/ScheduledVoices.vue";
import Invoices from "./tabs/Invoices.vue";
import InvoiceTransactionHistory from "./tabs/InvoiceTransactionHistory.vue";
import TransactionHistory from "./tabs/TransactionHistory.vue";
import Contacts from "./tabs/Contacts.vue";
import InvalidContacts from "./tabs/InvalidContacts.vue";

export default {
  directives: {
    Ripple,
    "b-modal": VBModal,
  },
  components: {
    BDropdown,
    BDropdownItem,
    BCard,
    BButton,
    BSpinner,
    BAvatar,
    BTab,
    BTabs,
    BBadge,
    BCol,
    BRow,
    GrantBalance,
    GrantBalancePostpaid,
    ChangeAccountType,
    SuspendAccount,
    RejectAccount,
    ActivateAccount,
    SenderId,
    Outbox,
    ScheduledMessages,
    OutboxVoices,
    ScheduledVoices,
    Invoices,
    InvoiceTransactionHistory,
    TransactionHistory,
    Contacts,
    InvalidContacts,
  },
  props: ["clientId"],
  computed: {
    avatarImage() {
      if (this.image) {
        return this.appClientUrl + "/uploads/profile-pictures/" + this.reqImage;
      }
      return require("@/assets/images/avatars/default-profile.png");
    },
  },
  data() {
    const { appClientUrl } = $themeConfig.app;
    return {
      firstName: null,
      lastName: null,
      email: null,
      phone: null,
      image: null,
      website: null,
      isCompany: null,
      status: null,
      postpaid: false,
      balance: null,
      format_created_at: null,
      contactsCounts: null,
      invalidContactsCounts: null,
      outboxCounts: null,
      scheduledCounts: null,
      outboxVoicesCounts: null,
      scheduledVoicesCounts: null,
      invoiceCount: null,
      transactionCount: null,
      senderIdCounts: null,
      transactionId: null,
      transactionInvoiceNumber: null,
      tabIndex: 0,
      isBusy: false,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      appClientUrl: appClientUrl,
    };
  },
  methods: {
    refreshClient() {
      this.$http
        .get("/clients/refresh-client/" + this.clientId)
        .then((response) => {
          const client = response.data.data;
          this.status = client.status;
          this.balance = client.balance;
        });
    },
    refreshClientAccountType(){
      this.postpaid = !this.postpaid;
    },
    refreshClientGrantBalance() {
      this.$http
        .get("/clients/refresh-client/" + this.clientId)
        .then((response) => {
          const client = response.data.data;
          this.status = client.status;
          this.balance = client.balance;

          this.$http
            .get("/credits/transaction-history/count?clientid=" + this.clientId)
            .then((response) => {
              this.transactionCount = response.data.data;
            });
        });
    },
    tabsCounts() {
      this.$http
        .get("/contacts/count?clientid=" + this.clientId)
        .then((response) => {
          this.contactsCounts = response.data.data;
        });

      this.$http
        .get("/contacts/count?invalid=true&clientid=" + this.clientId)
        .then((response) => {
          this.invalidContactsCounts = response.data.data;
        });

      this.$http
        .get("/scheduled-messages/count?clientid=" + this.clientId)
        .then((response) => {
          this.scheduledCounts = response.data.data;
        });

      this.$http
        .get("/voice/scheduled-messages/count?clientid=" + this.clientId)
        .then((response) => {
          this.scheduledVoicesCounts = response.data.data;
        });
    },
    tabsCountsOnce() {
      this.$http
        .get("/outbox/count?clientid=" + this.clientId)
        .then((response) => {
          this.outboxCounts = response.data.data;
        });

      this.$http
        .get("/voice/outbox/count?clientid=" + this.clientId)
        .then((response) => {
          this.outboxVoicesCounts = response.data.data;
        });

      this.$http
        .get("/invoices/count?clientid=" + this.clientId)
        .then((response) => {
          this.invoiceCount = response.data.data;
        });

      this.$http
        .get("/credits/transaction-history/count?clientid=" + this.clientId)
        .then((response) => {
          this.transactionCount = response.data.data;
        });

      this.$http
        .get("/sender-id/count?clientid=" + this.clientId)
        .then((response) => {
          this.senderIdCounts = response.data.data;
        });
    },
    viewTransactionHistory(id, invoiceNumber){
      this.transactionId = id;
      this.transactionInvoiceNumber = invoiceNumber;
      this.changeTabIndex(9);
    },
    changeTabIndex(index){
      this.tabIndex = index;
    }
  },
  created() {
    this.$http.get("/clients/" + this.clientId).then((response) => {
      const client = response.data.data;
      this.firstName = client.first_name;
      this.lastName = client.last_name;
      this.email = client.email;
      this.phone = client.code + client.phone;
      this.image = client.image;
      this.website = client.website;
      this.isCompany = client.is_company;
      this.status = client.status;
      this.postpaid = client.postpaid;
      this.balance = client.balance;
      this.format_created_at = client.format_created_at;
    });

    this.tabsCountsOnce();
    this.tabsCounts();
  },
};
</script>
<style scoped>
@media (max-width: 767px) {
  .table-lg {
    display: none;
  }
  .avatar-col {
    text-align: center;
    margin-bottom: 2.5rem !important;
  }
}
@media (max-width: 575px) {
  .table-md {
    display: none;
  }
}
@media (min-width: 1500px){
  .col-xxl-10 {
      flex: 0 0 83.3333333333%;
      max-width: 83.3333333333%;
  }
}
</style>
